
import Vue from "vue";

export default Vue.extend({
  name: "UsersOnline",

  data: () => ({
    items: [] as Array<any>,
    loadInterval: null as any,
    loading: false as boolean,
    menu: false as boolean,
    queryString: "" as string,
    hide: true as boolean
  }),

  computed: {
    user() {
      return this.$store.getters["user/info"];
    },
    filteredUsers() {
      return this.items.filter((item: any) =>
        item.name.toLowerCase().includes(this.queryString.toLowerCase())
      );
    }
  },

  async mounted() {
    await this.loadData();

    if (!this.hide) {
      this.loadInterval = setInterval(async () => {
        await this.loadData();
      }, 60000);
    }
  },

  methods: {
    async loadData() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      try {
        this.items = await this.$API.user().getOnline();
        this.hide = false;
      } catch (e) {
        await Promise.resolve();
      }
      this.loading = false;
    },
    openChat(item: any) {
      this.menu = false;
      this.$store.dispatch("chat/set", item.room_id);
    },
    async toProfile(item: any) {
      try {
        await this.$router.push(`/profile/${item.id}/personal`);
      } catch {
        await Promise.resolve();
      }
    },
    async setFocus(value: boolean) {
      clearInterval(this.loadInterval);

      if (value) {
        await this.loadData();
        this.loadInterval = setInterval(async () => {
          await this.loadData();
        }, 10000);
        setTimeout(() => {
          (this.$refs.search as Vue).focus();
        }, 300);
      } else {
        this.loadInterval = setInterval(async () => {
          await this.loadData();
        }, 60000);
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.loadInterval);
  }
});
