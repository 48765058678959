var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user)?_c('v-menu',{attrs:{"nudge-right":"-184","nudge-top":"-14","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"40"}},'v-avatar',attrs,false),on),[(_vm.user.avatar)?_c('v-img',{attrs:{"src":_vm.user.avatar.url}}):_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.user.name .split(" ") .map(function (ref) {
          var item = ref[0];

          return item;
}) .slice(0, 2) .join("") .toUpperCase())+" ")])],1)]}}],null,false,2086483999)},[_c('v-card',{staticClass:"align-center"},[_c('div',{staticClass:"pa-3 pr-10 d-flex align-center"},[_c('v-avatar',{attrs:{"size":"44"}},[(_vm.user && _vm.user.avatar)?_c('v-img',{attrs:{"src":_vm.user.avatar.url}}):_c('v-avatar',{staticClass:"white--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.user.name .split(" ") .slice(0, 2) .map(function (ref) {
          var item = ref[0];

          return item;
}) .join("") .toUpperCase())+" ")])],1),_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"name-text"},[_vm._v(" "+_vm._s(_vm.user && _vm.user.name)+" ")]),_c('div',{staticClass:"caption light_secondary--text"},[_vm._v(" "+_vm._s(_vm.user && _vm.user.email)+" ")])])],1),(_vm.$can('show', 'NavProfile'))?_c('v-divider'):_vm._e(),(_vm.$can('show', 'NavProfile'))?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{staticClass:"px-3",class:{ light_primary2: hover },attrs:{"dense":"","link":"","to":"/profile"}},[_c('v-list-item-icon',[_c('v-icon',{class:{ 'primary--text': hover }},[_vm._v("mdi-account")])],1),_c('v-list-item-content',{staticClass:"caption"},[_c('div',{staticClass:"text-left",class:{ 'primary--text': hover }},[_vm._v(" "+_vm._s(_vm.$t('modules.profile'))+" ")])])],1)]}}],null,false,1972224831)}):_vm._e(),_c('v-divider'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{directives:[{name:"confirm",rawName:"v-confirm",value:({
            title: 'Logout',
            message: 'Are you sure you want to log out?',
            callback: function () { return _vm.logout(); }
          }),expression:"{\n            title: 'Logout',\n            message: 'Are you sure you want to log out?',\n            callback: () => logout()\n          }"}],staticClass:"px-3",class:{ light_primary2: hover },attrs:{"dense":""}},[_c('v-list-item-icon',[_c('v-icon',{class:{ 'primary--text': hover }},[_vm._v("mdi-exit-to-app ")])],1),_c('v-list-item-content',{staticClass:"caption"},[_c('div',{staticClass:"text-left",class:{ 'primary--text': hover }},[_vm._v(" "+_vm._s(_vm.$t('menu.logout'))+" ")])])],1)]}}],null,false,4164982457)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }