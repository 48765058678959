
import Vue from "vue";

export default Vue.extend({
  name: "Profile",

  computed: {
    user() {
      return this.$store.getters["user/info"];
    }
  },

  methods: {
    async logout() {
      try {
        await this.$store.dispatch("authentication/logout");
        document.location.href = origin;
      } catch (e) {
        await this.$store.dispatch("alerts/showError", e.message);
      }
    }
  }
});
