
import Vue from "vue";

export default Vue.extend({
  name: "SideBar",

  data: () => ({
    logoImage: require("@/assets/images/logos/logo.svg"),
    logoText: require("@/assets/images/logos/logo-text.png"),
    items: [] as Array<MenuItemsInterface>
  }),

  computed: {
    expanded: {
      get(): boolean {
        return this.$store.getters["navigation/expanded"];
      },
      set(value: boolean): void {
        this.$store.dispatch("navigation/set", value);
      }
    },
    uncompletedTasks: {
        get(){
          return this.$store.getters['task/uncompletedTasks'];
        },
        set(value){
          this.$store.dispatch("task/set", value)
        }
    },

    currentUser(){
      return this.$store.getters["authentication/credentials"];
    },

    isAdmin(){
      return this.currentUser.user.role === 'admin';
    }
  },

  async mounted() {


    this.watchUser();


  },

  methods: {
    watchUser() {
      this.$watch(
        () => {
          return this.$store.getters["user/info"];
        },
        async user => {
          if (user) {
            const response: any = await import(`../../static/data/menu.json`);

            try{
              if(user.role.id !== 2)
              {
                this.uncompletedTasks = await this.$API.user().getNewTasksCount(this.currentUser.user.id);
              }
            }catch (e) {
              await this.$store.dispatch('alerts/showError', e.message)
            }



            this.items =
              (response.default[user.role.name] as Array<MenuItemsInterface>) ||
              [];
          }
        },
        {
          immediate: true
        }
      );
    },
    async logout(): Promise<void> {
      try {
        await this.$store.dispatch("authentication/logout");
        document.location.href = origin;
      } catch (e) {
        await this.$store.dispatch("alerts/showError", e.message);
      }
    },
    close(value: boolean): void {
      if (!value) {
        this.$store.dispatch("navigation/set", value);
      }
    },
    toMain(): void {
      window.location.href = "https://scoreboostsolution.com";
    },

    showMenuBubble(item: any){
      return item.text === 'Tasks' &&  !this.expanded && this.uncompletedTasks && !this.isAdmin
    },

    showTaskBadge(item: any){
      return item.text === 'Tasks' && this.uncompletedTasks && !this.isAdmin
    }
  }
});
