
import Vue from "vue";
import startCase from "lodash/fp/startCase";
import Profile from "@/components/navigation/Profile.vue";
import UsersOnline from "@/components/navigation/UsersOnlie.vue";

export default Vue.extend({
  name: "NavBar",

  components: { UsersOnline, Profile },

  data: () => ({
    startCase,
    closeOnContentClick: true as boolean,
    showSearch: false as boolean,
    checked: false as boolean,
    logoImage: require("@/assets/images/logos/logo-icon.png"),
    isProfile: null as any,
  }),

  computed: {
    moduleName() {
      return this.$route.name;
    },
    pageName(){
      const name = this.$store.getters['user/info'].name;
      let displayName = "";
      let nameArr = [];
      if (name.includes('‎')){
        nameArr = name.split('‎');
        displayName = nameArr[1] ? nameArr[1] : nameArr[0];
      } else {
        nameArr = name.split(" ");
        displayName = nameArr[1] ? nameArr[1] : nameArr[0];
      }
      return this.$route.name === 'profile' ? 'Hi, ' + displayName :  this.$t(`modules.${this.$route.name}`);
    }
  },

  methods: {
    toggleSideBar(): void {
      this.$store.dispatch("navigation/toggle");
    },
    toggleSearch(): void {
      this.showSearch = !this.showSearch;

      if (this.showSearch) {
        this.$nextTick(() => {
          (this.$refs.search as any).focus();
        });
      }
    }
  }
});
