
import Vue from "vue";
import Chat from "./Chat.vue";

export default Vue.extend({
  name: "ChatMenu",

  components: { Chat },

  data: () => ({
    menu: false,
    showChat: false,
    roomId: ""
  }),

  watch: {
    menu(val) {
      if (!val) {
        this.showChat = false;
      }
    }
  },

  mounted() {
    this.$watch(
      () => {
        return this.$store.getters["chat/roomId"];
      },
      value => {
        this.roomId = value;
        if (this.roomId) {
          this.loadChat();
        }
      }
    );
  },

  methods: {
    closeChat() {
      this.menu = false;
      this.showChat = false;
    },
    removeStoreRoom(value: boolean) {
      if (!value) {
        this.$store.dispatch("chat/remove");
      }
    },
    loadChat() {
      this.showChat = true;
    },
    openChat() {
      this.menu = true;
    }
  }
});
