
import Vue from "vue";
import NavBar from "@/components/navigation/NavBar.vue";
import Alert from "@/components/dialogs/Alert.vue";
import Preloader from "@/components/dialogs/Preloader.vue";
import SideBar from "@/components/navigation/SideBar.vue";
import ThemeSettings from "@/components/settings/ThemeSettings.vue";
import ChatMenu from "@/components/chat/ChatMenu.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";

export default Vue.extend({
  name: "Default",

  components: {ConfirmDialog, ChatMenu, SideBar, Preloader, Alert, NavBar },
  methods: {
    onidle() {
      localStorage.removeItem("credentials");
      window.location.reload();
    }
  }
});
